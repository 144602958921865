import { IGatsbyImageData } from "gatsby-plugin-image";
import BlogPostExcerptI from "../types/BlogPostExcerptI.type";
import BlogPostExcerptNodeI from "../types/BlogPostExcerptNodeI.type";
import FaqI from "../types/FaqI.type";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text";

// The following two functions used together can prevents one word from being on last line of paragraph of text
const removeLastTwoWords = (text: string) => {
  let splitText = text.split(" ");
  return splitText.splice(0, splitText.length - 2).join(" ");
};
const keepLastTwoWords = (text: string) => {
  let splitText = text.split(" ");
  return " " + splitText.splice(splitText.length - 2, 2).join(" ");
};

function truncateString(blogTitle: string, chars: number = 60): string {
  if (blogTitle.length > chars) {
    return blogTitle.substring(0, chars).trim() + "...";
  }
  return blogTitle;
}

const setStyle = (style: string) => {
  if (typeof window !== "undefined") {
    let styleElm = document.createElement("style");
    styleElm.innerHTML = style;
    document.body.append(styleElm);
  }
  return false;
};
const excerpt = (text: string, length: number, elipses: boolean = true) => {
  if (text && text.length <= length) {
    return text;
  } else {
    return text
      ? text.split(" ").slice(0, length).join(" ") + (elipses ? "..." : "")
      : "";
  }
};

const formatDate = (strDate: string) => {
  if (strDate === null) return "";
  let date = new Date(strDate.replace(/-/g, "/"));
  return date.toLocaleString("default", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const blogPostMapper = (
  data: Array<BlogPostExcerptI>
): Array<BlogPostExcerptNodeI> => {
  let nodes: Array<BlogPostExcerptNodeI> = [];
  for (let i = 0; i < data.length; i++) {
    let node = { node: data[i] };
    nodes.push(node);
  }
  return nodes;
};

function generateJsonLdScript(questions: Array<FaqI>): string {
  const faq = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": questions.map(qa => ({
      "@type": "Question",
      "name": qa.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": qa.answers.answers,
      },
    })),
  };
  return JSON.stringify(faq);
}

function estimateReadingTime(wordCount: number): string {
  const averageWordsPerMinute = 250;
  const minutes = Math.ceil(wordCount / averageWordsPerMinute);
  return `${minutes} min. read`;
}

type JSONContentNode = {
  nodeType: string;
  content: JSONContentNode[];
  value: string;
};

type JSONContent = {
  content: JSONContentNode[];
};

function getTruncatedContent(rawContent: string, maxLength: number): string {
  const content = JSON.parse(rawContent);

  let truncatedContent = "";
  let charCount = 0;

  content.content.some((node: { nodeType: string; content?: any[] }) => {
    if (charCount >= maxLength) {
      return true;
    }
    if (node.nodeType === "paragraph") {
      node.content?.forEach(
        (innerNode: {
          nodeType: string;
          value?: string;
          content?: JSONContentNode[];
        }) => {
          if (innerNode.nodeType === "text") {
            const remainingChars = maxLength - charCount;
            if (innerNode.value!.length > remainingChars) {
              truncatedContent += innerNode.value!.slice(0, remainingChars);
              charCount = maxLength;
            } else {
              truncatedContent += innerNode.value! + " ";
              charCount += innerNode.value!.length;
            }
          } else if (innerNode.nodeType === "hyperlink") {
            innerNode.content?.forEach((hyperlinkNode: JSONContentNode) => {
              if (hyperlinkNode.nodeType === "text") {
                const remainingChars = maxLength - charCount;
                if (hyperlinkNode.value!.length > remainingChars) {
                  truncatedContent += hyperlinkNode.value!.slice(
                    0,
                    remainingChars
                  );
                  charCount = maxLength;
                } else {
                  truncatedContent += hyperlinkNode.value!;
                  charCount += hyperlinkNode.value!.length;
                }
              }
            });
          }
        }
      );
    }
    return false;
  });

  if (charCount > maxLength - 1) {
    truncatedContent = truncatedContent.slice(0, maxLength - 1);
    truncatedContent = truncatedContent.replace(/\s+$/, "");
    truncatedContent += "…";
  }

  return truncatedContent;
}

function getWordCount(contents: JSONContent[]): number {
  let wordCount = 0;
  contents.forEach(content => {
    wordCount += content.content.reduce(
      (count: number, node: JSONContentNode) => {
        if (node.nodeType === "text" && node.value) {
          count += node.value.trim().split(/\s+/).length;
        } else if (node.content) {
          count += getWordCount([{ content: node.content }]);
        }
        return count;
      },
      0
    );
  });
  return wordCount;
}

function getWordCountFromRaw(rawRichTextArr: string[]): number {
  const contentsArr: JSONContent[] = [];
  rawRichTextArr.forEach(raw => {
    if (raw) {
      contentsArr.push(JSON.parse(raw));
    }
  });
  return getWordCount(contentsArr);
}

function getReadingTimeFromRaw(rawRichTextArr: string[]): string {
  return estimateReadingTime(getWordCountFromRaw(rawRichTextArr));
}

function getBorderRadius(
  itemsCount: number,
  columns: number,
  index: number
): string {
  let borderRadius = "";

  if (itemsCount === 1) {
    // Only one item, set all corners to round
    borderRadius = "10px";
  } else if (columns === 1) {
    // Only one column, set top left and bottom left corners to round
    if (index === 0) {
      borderRadius = "10px 10px 0 0";
    } else if (index === itemsCount - 1) {
      borderRadius = "0 0 10px 10px";
    }
  } else {
    // Multiple columns, set appropriate corners to round
    const isFirstColumn = index % columns === 0;
    const isLastColumn = index % columns === columns - 1;
    const isFirstRow = index < columns;
    const isLastRow = index >= itemsCount - columns;

    if (isFirstColumn && isFirstRow) {
      borderRadius = "10px 0 0 0";
    } else if (isFirstColumn && isLastRow) {
      borderRadius = "0 0 0 10px";
    } else if (isLastColumn && isFirstRow) {
      borderRadius = "0 10px 0 0";
    } else if (isLastColumn && isLastRow) {
      borderRadius = "0 0 10px 0";
    }
  }

  return borderRadius;
}

function isGatsbyImage(object: any): object is {
  gatsbyImageData: IGatsbyImageData;
  title: string;
} {
  return object?.gatsbyImageData ? true : false;
}

function isGatsbyRichtext(
  object: any
): object is RenderRichTextData<ContentfulRichTextGatsbyReference> {
  return object?.raw ? true : false;
}

function extractSVGUrl(input: string): string {
  const urlRegex = /\/_gatsby\/file\/[^/]+(.+?)\?u=(.+)/;
  const match = input.match(urlRegex);
  
  if (match && match.length === 3) {
    const [, path, queryParams] = match;
    const decodedQueryParams = decodeURIComponent(queryParams);
    return decodedQueryParams;
  }
  
  return "";
}

export {
  extractSVGUrl,
  isGatsbyRichtext,
  isGatsbyImage,
  getBorderRadius,
  removeLastTwoWords,
  keepLastTwoWords,
  truncateString,
  setStyle,
  excerpt,
  formatDate,
  blogPostMapper,
  generateJsonLdScript,
  getReadingTimeFromRaw,
  getTruncatedContent,
};
