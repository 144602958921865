import { Helmet } from "react-helmet";
import { generateJsonLdScript } from "../../utils/helpers";
import SEOI from "./SEO.type";

const SEO = ({
  metaTitle,
  metaDescription,
  keywords,
  preventIndexing,
  url,
  rating = false,
  faqs,
  alternateHrefLink,
}: SEOI) => {
  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} key="description" />
      <meta name="keywords" content={keywords} />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta property="og:url" content={url} key="og:url" />
      <meta property="og:title" content={metaTitle} key="og:title" />
      <meta
        property="og:description"
        content={metaDescription}
        key="og:description"
      />
      {preventIndexing && <meta name="robots" content="noindex" />}
      {alternateHrefLink && <link rel="alternate" hrefLang="x" href="" />}
      <link
        rel="canonical"
        href={
          (typeof window !== "undefined"
            ? window.location.origin
            : "https://via.work") + url
        }
      />
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-na1.hs-scripts.com/8466054.js"
      />
      {url === "/" && (
        <script type="application/ld+json">
          &#123; "@context": "http://schema.org", "@type": "WebSite", "name":
          "Via", "alternateName": "Via work", "url": "https://via.work/" &#125;
        </script>
      )}
      {faqs && (
        <script type="application/ld+json">{generateJsonLdScript(faqs)}</script>
      )}
      {rating && (
        <script type="application/ld+json">
          &#123; "@context": "https://schema.org", "@graph": &#91; &#123;
          "@type": "CreativeWorkSeries", "name": "Via", "aggregateRating":
          &#123; "@type": "AggregateRating", "ratingValue": "4.8",
          "ratingCount": "5725" &#125; &#125; &#93; &#125;
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
